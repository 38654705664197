var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: { d: "M11 4L11 18", stroke: "#606266", "stroke-width": "1.5" },
      }),
      _c("path", {
        attrs: {
          d: "M17 12L11 18L5 12",
          stroke: "#606266",
          "stroke-width": "1.5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }