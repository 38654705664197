<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 4L11 18" stroke="#606266" stroke-width="1.5" />
    <path d="M17 12L11 18L5 12" stroke="#606266" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDown',
}
</script>
